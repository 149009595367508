import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    currentCaseId: 0,
    pagetwoMoreShow: false,
    pagetwoMoreSlideShow: false,
    pagetwoSlideShow: false,
    pagefiveJoinusShow: false
  },
  mutations: {
    setPagetwoMoreShow(state, val) {
      state.pagetwoMoreShow = val;
    },
    setPagetwoMoreSlideShow(state, val) {
      state.pagetwoMoreSlideShow = val.visible;
      state.currentCaseId = val.id;
    },
    setPagetwoSlideShow(state, val) {
      state.pagetwoSlideShow = val.visible;
      state.currentCaseId = val.id;
    },
    setPagefiveJoinusShow(state, val) {
      state.pagefiveJoinusShow = val;
    },
  },
  actions: {
    setPagetwoMoreShowC(context, val) {
      context.commit('setPagetwoMoreShow', val);
    },
    setPagetwoMoreSlideShowC(context, val) {
      context.commit('setPagetwoMoreSlideShow', val);
    },
    setPagetwoSlideShowC(context, val) {
      context.commit('setPagetwoSlideShow', val);
    },
    setPagefiveJoinusShowC(context, val) {
      context.commit('setPagefiveJoinusShow', val);
    },
  },
  modules: {
  }
})
