import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/app',
    name: 'app',
    component: () => import('@/views/app/index.vue'),
    children: [{
      path: '/',
      name: 'Home',
      component: () => import('@/views/app/Home/index.vue')
    }, {
      path: '/Contact',
      name: 'Contact',
      component: () => import('@/views/app/Contact/index.vue')
    }, {
      path: '/Example',
      name: 'Example',
      component: () => import('@/views/app/Example/index.vue')
    }, {
      path: '/ExampleDetail',
      name: 'ExampleDetail',
      component: () => import('@/views/app/ExampleDetail/index.vue')
    }]
  },
  {
    path: '/web',
    name: 'web',
    component: () => import('@/views/web/Home/index.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
