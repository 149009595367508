<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  mounted() {
    if (this.isPC()) {
      this.$router.push("/web");
    } else {
      this.$router.push("/app");
    }
  },
  methods: {
    // 判断PC || mobile
    isPC() {
      let userAgentInfo = navigator.userAgent;
      let agents = new Array(
        "Android",
        "iPhone",
        "SymbianOS",
        "Windows Phone",
        "iPad",
        "iPod"
      );

      let flag = true;
      for (let i = 0; i < agents.length; i++) {
        if (userAgentInfo.indexOf(agents[i]) > 0) {
          flag = false;
          break;
        }
      }
      return flag;
    },
    isMOBILE() {
      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        return "iPhone";
      } else if (/(Android)/i.test(navigator.userAgent)) {
        return "Android";
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
#app {
  height: 100%;
  overflow: hidden;
}
</style>
